import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Card, CardContent, Typography, Grid, Chip
} from "@material-ui/core";
import AlertOverview from '../Alerts/AlertOverview';
import utils from '../../utils';
const { t, dayjsFormatLocal } = utils;



const AlertsTab = ({ locationId, outletClientId }) => {
    const gridColumns = (tOpts, key) => {
        const columns = {
          alertOverview: [
            { id: "AlertId", label: "ID", flex: 0, minWidth: 60, valueGetter: (value) => value.row.AlertId },
            { id: "AlertType", label: "Alert Type", flex: 0, minWidth: 100, valueGetter: (value) => value.row.AlertType },
            { id: "AlertText", label: "Alert Text", flex: 0, minWidth: 100, valueGetter: (value) => value.row.AlertText },
            {
              id: "StatusId", label: "Status", flex: 0, minWidth: 120, filterable: false, valueGetter: (value) => value.row.StatusId, format: (item) => {
                return item.value === 1 ? 'New' : 'Closed';
              }
            },
            {
              id: "AlertAt", label: "Alert At", flex: 0, minWidth: 100, valueGetter: (value) => value.row.AlertAt, format: (item) => {
                return utils.formatDate(item.value, false);
              }
            },
            { id: "Age", label: "Age (Days)", type: "number", flex: 0, minWidth: 60, valueGetter: (value) => value.row.Age },
            {
              id: "ScheduleDate", label: "Schedule Date", type: "date", flex: 0, minWidth: 100, valueGetter: (value) => value.row.ScheduleDate, format: (item) => {
                return dayjsFormatLocal({ value: item.value, format: utils.systemDateTimeFormat(), tOpts: tOpts, lang: null });
              }
            },
            { id: "SLAHours", label: "SLA Hours", type: "number", flex: 0, minWidth: 100, valueGetter: (value) => value.row.SLAHours },
            { id: "Owner.PrimaryEmail", label: "Owner", flex: 0, minWidth: 200, valueGetter: (value) => value.row.OwnerName },
            { id: "Manager.PrimaryEmail", label: "Manager", flex: 0, minWidth: 200, valueGetter: (value) => value.row.ManagerName },
            { id: "Asset.SerialNumber", label: "Asset Serial #", flex: 0, minWidth: 100, valueGetter: (value) => value.row.AssetSerialNumber }
          ]
        }
        return columns[key];
      }
    const { t: translate, i18n } = useTranslation();
    const [openAlertCount, setOpenAlertCount] = useState(0);
    const tOpts = { t: translate, i18n };

    return <>
        <Card>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid className='mb-2' item xs={12} sm={12} md={12} lg={12} >
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <Typography className='' variant="h4" gutterBottom>{t("Total Alerts", tOpts)}<span><Chip id="red-chip" className='ml-2' label={openAlertCount} /></span></Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ height: "500px" }}>
                                <AlertOverview isOutletCard={true} gridColumn={gridColumns(tOpts, 'alertOverview')} setAlertCount={setOpenAlertCount} location={locationId}
                                    sort={[{ field: "Alert.StatusId", sort: "asc" }, { field: "Age", sort: "desc" }]} clientId={outletClientId} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </>
}

export default AlertsTab;
