import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import utils from '../../utils';
import constants from '../../utils/constants'
import {
    Card, CardContent, Grid, Tooltip
} from "@material-ui/core";
import dayjs from "dayjs";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DropDownMenu from "../Common/DropDownMenu";
import swal from 'sweetalert';
import { request, apis } from "../../httpUtil";
import GridBase from "../GridBase";
import { useTranslation } from "react-i18next";
import { InfoRounded } from '@material-ui/icons';
import kpiDescription from '../../utils/kpiDescription.json'
import useMobile from '../../utils/useMobile';
import * as JsError from '../../JsError';
const { getDateFilter, daysDropDown, getMetricFilters, metricsCategoryMappings, kpiNames, kpiUnits, t } = utils;
const { customOrder } = constants;
const metricsCategory = metricsCategoryMappings();
const dateFilterMaxDate = new Date();
let weekDate = new Date();
weekDate = weekDate.getDate() - 7;
weekDate = new Date().setDate(weekDate)

const tableColumns = ({ t, tOpts, key, kpiData }) => {

    const columns = {
        kpi: [
            { id: "subCategory", label: `${t("Sub-Category", tOpts)}`, flex: 0, minWidth: 150, sortable: false, valueGetter: (value) => t(value.row.subCategory, tOpts) || '-' },
            {
                id: "label", label: `${t("KPI", tOpts)}`, flex: 0, minWidth: 250, sortable: false, valueGetter: (value) => value.row, formatJSX: (item) => {
                    const { dataKey, label } = item.value;
                    return <div className="w-100">{t(label, tOpts)}
                        <Tooltip title={t(kpiDescription[dataKey] || label, tOpts)} placement="right">
                            <span className="float-right"><InfoRounded style={{ height: "15px" }} /></span>
                        </Tooltip></div>
                }
            },
            {
                id: "value", label: `${t("Value", tOpts)}`, flex: 0, minWidth: 100, sortable: false, valueGetter: (value) => value.row, format: (item) => {
                    const { dataKey, value, label } = item.value;
                    if (kpiData.IsVerticalCoolerPresent && label === "Occupancy") {
                        return 'N/A'
                    }
                    return value === null ? "-" : (Number(value).toFixed(1) + " " + kpiUnits[dataKey]);
                }
            }
        ],
        product: [
            { id: "Product", label: `${t("Product", tOpts)}`, flex: 0, minWidth: 300, valueGetter: (value) => value.row.Product || '-' },
            { id: "SKU", label: `${t("SKU", tOpts)}`, flex: 0, minWidth: 150, valueGetter: (value) => value.row.SKU || '-' },
            {
                id: "ProductDistribution", label: `%`, flex: 0, minWidth: 100, valueGetter: (value) => value.row.ProductDistribution, format: (item) => {
                    return Number(item.value).toFixed(1) + "%";
                }
            }
        ]
    }

    return columns[key];
}

const OutletPerformance = (props) => {
    const { locationId, outletClientId = 0 } = props;

    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const dispatch = useDispatch();
    const history = useHistory();
    const isMobile = useMobile();
    const [filter, setFilter] = useState({
        activePeriods: ['Custom'],
        daysFilter: "7:day",
        fromDate: new Date(weekDate),
        toDate: dateFilterMaxDate,
    });
    const [metrics, setMetrics] = useState([]);
    const [kpiData, setKpiData] = useState({ kpi: [], product: [] });
    const userData = useSelector(state => state.appReducer.userData);
    const dateFormat = userData?.tags?.DateFormat || "MM/dd/yyyy";

    useEffect(() => {
        loadData()
    }, [filter])


    async function loadData() {
        try {
            let metricL = [...metrics], kpiDataL = { ...kpiData };
            if (!metrics.length) {
                const response = await getMetricFilters({ history, dispatch, clientId: outletClientId });
                if (response && response.length) {
                    let metricDB = [];
                    for (const element of response) {
                        if (element.dashboardView) {
                            metricDB.push({
                                checked: true,
                                dataKey: element.dataKey,
                                category: "Merchandising",
                                subCategory: metricsCategory[element.dataKey],
                                label: element.label,
                                metricsValue: (element.dataKey === kpiNames.salesOpportunity) ? "Sum" : "Avg",
                                ranges: []
                            })
                        }
                    }
                    metricL = [...metricDB]
                    setMetrics(metricL);
                } else {
                    return swal({
                        title: 'Unable to get KPI List',
                        text: "",
                        icon: "info",
                        dangerMode: false
                    });
                }
            }

            const payload = { locationId: locationId, dataTypes: ['kpi', 'productDistribution'], filters: {} };
            if (outletClientId > 0) {
                payload.clientId = outletClientId;
            }
            let startDate = dayjs(filter.fromDate).format(utils.dateFormat)
            let endDate = dayjs(filter.toDate).format(utils.dateFormat)

            if ((startDate && dayjs(startDate).isValid()) && (endDate && dayjs(endDate).isValid())) {
                payload.filters.fromDate = startDate
                payload.filters.toDate = endDate
                payload.kpiMeasures = metricL;

                const response = await request({ url: apis.OutletCardView, params: payload, history, dispatch, jsonPayload: true });
                if (response.KPIInfo) {
                    kpiDataL.IsVerticalCoolerPresent = response.KPIInfo.IsVerticalCoolerPresent;
                    let orderedData = [];
                    for (let item of customOrder) {
                        const kpiObj = metricL.find(v => v.label === item);
                        if (kpiObj) {
                            orderedData.push({
                                ...kpiObj,
                                value: response.KPIInfo[kpiObj.dataKey]
                            })
                        }
                    }
                    kpiDataL.kpi = orderedData;
                }
                if (response.productDistribution) {
                    kpiDataL.product = response.productDistribution;
                }

                setKpiData(kpiDataL)
            };

        } catch (error) {
            JsError.handleGlobalError({ url: apis.SendErrorEmail, errorMsg: 'Error on loading KPI data', errorObj: error, appName: 'Playbook' });
        }
    }

    const handleChange = (event, selected, options, type) => {
        const { name, value } = event.target;
        const filterObj = { ...filter };
        filterObj[name] = value;
        if (value !== '-1') {
            const obj = getDateFilter(value, dateFilterMaxDate);;
            filterObj['fromDate'] = obj.fromDate;
            filterObj['toDate'] = obj.toDate;
        }
        setFilter(filterObj);
    };

    const handleDateChange = (date, field) => {
        const filterObj = { ...filter };
        if (field === 'fromDate' && dayjs(date).isAfter(dayjs(filterObj.toDate))) {
            return ''
        } else if (field === "toDate" && dayjs(date).isBefore(dayjs(filterObj.fromDate))) {
            return ''
        }
        filterObj[field] = date;
        setFilter(filterObj);
    };


    return (
        <Card className='pt-0 m-0 pl-1 pr-1 pb-1'>
            <CardContent className='pt-0 m-0 pl-1 pr-1 pb-1'>
                <Grid container spacing={1}>

                    <Grid className='mb-1' item xs={12} sm={6} md={6} lg={6} >
                        <DropDownMenu
                            classNameParent="w-75 mt-4"
                            options={daysDropDown}
                            name="daysFilter"
                            separateLabel={true}
                            label="Date Range"
                            handleChange={handleChange}
                            value={filter.daysFilter}
                            hideMenuLabel={true}
                            size='small' />
                    </Grid>

                    <Grid className='mb-1' item xs={12} sm={6} md={6} lg={6} >
                        <Grid container spacing={1}>
                            <Grid item xs={5} sm={5} md={5} lg={5} className="">
                                <KeyboardDatePicker
                                    id="date-picker-dialog-1"
                                    className='w-100'
                                    margin="normal"
                                    required
                                    label={t("Start Date", tOpts)}
                                    name="fromDate"
                                    format={dateFormat}
                                    value={filter.fromDate}
                                    onChange={(e) => handleDateChange(e, "fromDate")}
                                    KeyboardButtonProps={{ "aria-label": "change date" }}
                                    disableFuture={true}
                                    invalidDateMessage={t("Invalid Date Format", tOpts)}
                                    minDateMessage={t('Date should not be before minimal date', tOpts)}
                                    maxDateMessage={t('Date should not be after maximal date', tOpts)}
                                    disabled={props.customDate === "Disabled" ? true : filter.daysFilter !== '-1'}
                                    maxDate={new Date()} />
                                {filter.fromDate ? '' : <span className='date-picker-validation-style'>{`${t('Start Date is required', tOpts)} *`}</span>}
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} className="mt-4 pt-3 text-center">{t('To', tOpts)}</Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} className="">
                                <KeyboardDatePicker
                                    id="date-picker-dialog-2"
                                    className='w-100'
                                    required
                                    margin="normal"
                                    name="toDate"
                                    label={t("End Date", tOpts)}
                                    format={dateFormat}
                                    value={filter.toDate}
                                    invalidDateMessage={t("Invalid Date Format", tOpts)}
                                    minDateMessage={t('Date should not be before minimal date', tOpts)}
                                    maxDateMessage={t('Date should not be after maximal date', tOpts)}
                                    onChange={(e) => handleDateChange(e, "toDate")}
                                    KeyboardButtonProps={{ "aria-label": "change date" }}
                                    disableFuture={true}
                                    disabled={props.customDate === "Disabled" ? true : filter.daysFilter !== '-1'}
                                    maxDate={new Date()} />
                                {filter.toDate ? '' : <span className='date-picker-validation-style'>{`${t('End date is required', tOpts)} *`}</span>}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={isMobile.mobile && !isMobile.tablet ? 'mb-4' : 'mb-1'} item xs={12} sm={6} md={6} lg={6} style={{ height: '420px' }}>
                        <div className='text-left data-grid-font-12'><span className='data-grid-header p-2'>{t("Merchandising", tOpts)}</span></div>
                        <GridBase
                            customClass={'mt-2 data-grid-font-12'}
                            columns={tableColumns({ t, tOpts, key: "kpi", kpiData })}
                            data={kpiData.kpi}
                            pagination={false}
                            autoHeight={false}
                            disableColumnMenu={true}
                            otherOptions={{ disableSelectionOnClick: true, density: "compact", hideFooter: true }} />
                    </Grid>

                    <Grid className={isMobile.mobile && !isMobile.tablet ? 'mb-4' : 'mb-1'} item xs={12} sm={6} md={6} lg={6} style={{ height: '420px' }}>
                        <div className='text-left data-grid-font-12'><span className='data-grid-header p-2'>{t("Portfolio", tOpts)}</span></div>
                        <GridBase
                            customClass={'mt-2 data-grid-font-12'}
                            columns={tableColumns({ t, tOpts, key: "product" })}
                            data={kpiData.product}
                            pagination={false}
                            autoHeight={false}
                            otherOptions={{ disableSelectionOnClick: true, density: "compact", hideFooter: true }} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default OutletPerformance;