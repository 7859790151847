import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { ListItem, Button, Collapse, Tooltip } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import utils from "../../utils";
import { useTranslation } from "react-i18next";

const t = utils.t;
const CustomRouterLink = forwardRef(function CustomLink(props, ref) {
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  );
});

const SidebarMenuListItem = props => {
  const dispatch = useDispatch();
  const { title, href, depth, children, icon: Icon, iconclass, className, open: openProp, label: Label, active = false, ...rest } = props;
  const sidebarToggle = useSelector(state => state.appReducer.sidebarToggle);
  const [open, setOpen] = useState(openProp);
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };

  const sidebarToggleMobile = useSelector(state => state.appReducer.sidebarToggleMobile);
  const toggleSidebarItemMobile = () => {
    dispatch({ type: actions.SET_SIDEBAR_TOGGLE_MOBILE, sidebarToggleMobile: !sidebarToggleMobile })
    dispatch({ type: actions.SET_OPERATIONS_INFORMATION, operationsCheckedData: { onExportClick: null } });
    dispatch({
      type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
        filterButton: null,
        hidden: { search: true, operation: true, export: true, print: true, filter: true }
      },
    });
  };

  const handleToggle = () => {
    setOpen(open => !open);
  };

  let paddingLeft = 14;
  if (depth > 0) { paddingLeft = sidebarToggle ? 30 : (32 + 20 * depth); }
  const style = { paddingLeft, background: depth > 0 ? 'none' : '' };
  return (<>
    <Tooltip open={sidebarToggle ? toolTipOpen : false} key={title + '_TT'} title={t(title, tOpts)} placement='right' arrow>
      <ListItem  {...rest} className={clsx('app-sidebar-item', className)} disableGutters>
        {children ? <>
          <span onMouseEnter={() => setToolTipOpen(true)} onMouseLeave={() => setToolTipOpen(false)} >
            <Button
              color="primary"
              className={clsx('app-sidebar-button', { active: active || open })}
              onClick={handleToggle}
              style={style}>
              {Icon && <Icon key={title + '_IC'} className="app-sidebar-icon" />}
              <span className="menu-item-list-title">{t(title, tOpts)}</span>
              {open ? <ExpandLessIcon className="sidebar-expand-icon" color="inherit" />
                : <ExpandLessIcon className="sidebar-expand-icon sidebar-expand-icon-rotate" color="inherit" />}

            </Button>
          </span>
          <Collapse in={open}>{children}</Collapse>
        </> : <>
          <span onMouseEnter={() => setToolTipOpen(true)} onMouseLeave={() => setToolTipOpen(false)}  >
            <Button
              exact
              disableRipple
              variant="text"
              color="primary"
              className={clsx('app-sidebar-button-wrapper', `depth-${depth}`,{ active: active })}
              activeClassName="active-item"
              onClick={toggleSidebarItemMobile}
              component={CustomRouterLink}
              style={style}
              to={href}>
              {Icon && <Icon className="app-sidebar-icon" />}
              <span className="menu-item-list-title">{t(title, tOpts)}</span>
              {Label && <span className="menu-item-label"><Label /></span>}
            </Button>
          </span>
        </>}
      </ListItem>
    </Tooltip>
  </>)
};

export default SidebarMenuListItem;