import React from 'react';
import * as JsError from './JsError';
import { apis } from './httpUtil';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
       return { hasError: true };
    }

    componentDidCatch(error, info) {
        JsError.handleGlobalError({ url: apis.SendErrorEmail, errorMsg: 'Boundary Error', errorObj: error, appName: 'Playbook' });
    }

    render() {
     
        return this.props.children;
    }
}

export default ErrorBoundary;
