
import React from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { withRouter } from "react-router";
import utils from "../../utils";
import { Button } from "@mui/material";

const t = utils.t;
function Export({ t: trans, i18n }) {
    const tOpts = { t: trans, i18n };
    const { onExportClick } = useSelector(state => state.appReducer.operationsCheckedData) || {};
    return onExportClick && <>
        <Button size="small" variant="contained" onClick={onExportClick} color={'primary'} className={'m-1 background-theme-blue'} style={{ textTransform: 'none' }} >
            {t("Export", tOpts)}
        </Button>
    </>
}

export default withTranslation()(withRouter(Export));