import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Card, Grid, Tab } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        maxHeight: props => `${props.maxHeight}vh`,
        overflowY: "auto",
    },
    image: {
        width: "100%",
        height: "auto",
        margin: '0 auto',
        marginBottom: '10px'
    }
}));
export const TextComparator = (props) => {
    const { answer, classes, question, openPopUp, comparatorSelected, lookupData } = props;
    let categories = answer.comparatorData.categories.map(category => {
        const isNotNullOrUndefined = category !== null && category !== undefined;
        const categoryStr = isNotNullOrUndefined ? category.toString() : '';
        return {
            originalValue: categoryStr,
            label: categoryStr
        };
    });
    categories.sort((a, b) => {
        const aLabel = a.label || '';
        const bLabel = b.label || '';
        if (aLabel === 'Blank') return 1;
        if (bLabel === 'Blank') return -1;
        return aLabel.localeCompare(bLabel);
    });
    const plotData = answer.comparatorData.series.sort((a, b) => {
        const aName = a.name || '';
        const bName = b.name || '';
        if (aName === 'Blank') return 1;
        if (bName === 'Blank') return -1;
        return aName.localeCompare(bName);
    });
    const initialTabValue = categories.length > 0 ? categories[0].originalValue : '';
    const [tabValue, setTabValue] = useState(initialTabValue);
    const [maxHeight, setMaxHeight] = useState(55);
    const isFiles = ["file", "multiFile", "scene"].includes(question?.type) && !question?.beforeAfterImage;
    const styleClasses = useStyles({ maxHeight });
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    if (comparatorSelected && lookupData[comparatorSelected] && (comparatorSelected !== 'User') && (comparatorSelected !== 'Time')) {
        const fData = lookupData[comparatorSelected];
        categories = categories.map(categoryObj => {
            const categoryIds = categoryObj.originalValue.split(',').map(id => id.trim());
            const displayValues = categoryIds.map(categoryId => {
                const item = fData.find(item => item.LookupId.toString() === categoryId.trim());
                return item ? item.DisplayValue : "Blank";
            });
            return { ...categoryObj, label: displayValues.join(', ') };
        });
    }

    useEffect(() => {
        const handleResize = () => {
            const newMaxHeight = Math.min(55, window.innerHeight * 0.8);
            setMaxHeight(newMaxHeight);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const renderContentForCategory = (value) => {
        return <div key={`${value[0]}-${value[1]}`}>{`[${value[1]}] ${value[0]}`}</div>;
    };

    const renderGalleryContentForCategory = (value, values, idx) => {
        const pValues = values.map(url => ({ url }));
        return (
            <div
                key={idx}
                className="gallery-item"
                style={{
                    margin: '5px',
                    textAlign: 'center',
                    cursor: 'pointer',
                }}
            >
                <img
                    src={value}
                    alt={`Image ${idx}`}
                    className="survey-view-image"
                    style={{
                        maxWidth: '100%',
                        borderRadius: '4px',
                    }}
                    onClick={(e) => openPopUp(e, value, idx, pValues)}
                    onKeyDown={(e) => openPopUp(e, value, idx, pValues)}
                />
            </div>
        );
    };
    return (
        <div>
            <TabContext value={tabValue}>
                <TabList variant="scrollable" scrollButtons="auto" aria-label="Horizontal tabs" onChange={handleTabChange}>
                    {categories.map((category) => (
                        <Tab key={category.originalValue} label={category.label} value={category.originalValue} />
                    ))}
                </TabList>
                {plotData.map(({ category, values }) => (
                    <TabPanel key={category} value={category}>
                        <Grid container spacing={2} className={classes.cardContainer} style={{ maxHeight: `${maxHeight}vh`, overflowY: 'auto' }}>
                            {values.map((value, idx) => {
                                if (isFiles) {
                                    return (
                                        <Grid item xs={12} sm={6} md={3} lg={1.2} key={idx}>
                                            {renderGalleryContentForCategory(value, values, idx)}
                                        </Grid>
                                    );
                                } else {
                                    return (
                                        <Grid item xs={3} key={`${idx}`} style={{ display: "flex" }}>
                                            <Card className={`${classes.stringType} comparison-text-view`}>
                                                {renderContentForCategory(value)}
                                            </Card>
                                        </Grid>
                                    );
                                }
                            })}
                        </Grid>
                    </TabPanel>
                ))}
            </TabContext>
        </div>
    );

};
