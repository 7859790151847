import dayjs from 'dayjs';
import UiModel from './ui-model';
import Button from '@mui/material/Button';
import constants from '../utils/constants';

const filterDate = dayjs().subtract(7, 'day').format("YYYY-MM-DD");

const orderSuggestionHistoryModel = new UiModel({
    title: "Order Suggestion History",
    defaultSort: 'TimeStamp DESC',
    titleDescription: 'Understand the history of the suggestive orders',
    idProperty: 'id',
    readOnly: true,
    addCreatedModifiedColumns: false,
    module: 'OrderSuggestionHistory',
    defaultFilters: [{ field: 'TimeStamp', operator: 'onOrAfter', value: filterDate }],
    isElasticScreen: true,
    preferenceId: 'playbookOrderSuggestionHistory',
    showExportWithLatestData: true,
    columns: [
        { field: "TimeStamp", type: 'dateTime', width: 300, label: "Status Date", pinned: true, keepUTC: true },
        { field: "LocationName", type: 'string', width: 310, label: "Outlet Name", filterField: 'LocationName.keyword', isKeywordField: true },
        { field: "LocationCode", type: 'string', width: 310, label: "Outlet Code", filterField: 'LocationCode.keyword', isKeywordField: true },
        { field: "TotalOrder", type: 'number', width: 200, label: "Value", pinned: 'right' },
        { field: "TotalCase", type: 'number', width: 200, label: "Cases", pinned: 'right' },
        {
            field: "OrderStatusId", type: 'radio', lookup: "OrderStatus", width: 200, applyZeroFilter: true, label: "Status", pinned: 'right',
            renderCell: (value) => {
                const { value: columnValue } = value;
                let statusText = '';
                let statusColor = '';
                if (columnValue === constants.orderStatusIdEnum.Inbox.value) {
                    statusText = constants.orderStatusIdEnum.Inbox.statusText;
                    statusColor = constants.orderStatusIdEnum.Inbox.statusColor;
                } else if (columnValue === constants.orderStatusIdEnum.Schedule.value) {
                    statusText = constants.orderStatusIdEnum.Schedule.statusText;
                    statusColor = constants.orderStatusIdEnum.Schedule.statusColor;
                } else if (columnValue === constants.orderStatusIdEnum.InTransit.value) {
                    statusText = constants.orderStatusIdEnum.InTransit.statusText;
                    statusColor = constants.orderStatusIdEnum.InTransit.statusColor;
                } else if (columnValue === constants.orderStatusIdEnum.Ignored.value) {
                    statusText = constants.orderStatusIdEnum.Ignored.statusText;
                    statusColor = constants.orderStatusIdEnum.Ignored.statusColor;
                }
                return (
                    <div className="custom-button">
                        <Button variant="outlined" color={statusColor} className="custom-button" >{statusText}</Button>
                    </div>
                );
            }
        },
    ]
});

export default orderSuggestionHistoryModel;