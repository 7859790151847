import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import swal from 'sweetalert';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import MultiSelectDropdown from '../mutliSelectDropdown';
import actions from '../../redux/actions';
import PageTitle from '../PageTitle';
import missedOpportunityOutletModel from '../../pages/missed-opportunity-outlet';
import utils from '../../utils'
import { useTranslation } from 'react-i18next';

const cardsToRender = [
    { key: "ActualRevenue", title: "Actual Revenue", value: 'actualRevenue', addCurrencySymbol: true, ml: 25 },
    { key: "MissedRevenue", title: "Missed Revenue", value: 'missedRevenue', addCurrencySymbol: true, ml: 25 },
    { key: "UpliftPotential", title: "Uplift Potential", value: 'upliftPotential', ml: 25, addPercentageSymbol: true },
    { key: "AverageDailyUnitsSoldPerAsset", title: "Average Daily Units Sold Per Asset", value: 'averageUnitsSold', ml: 60 },
    { key: "AverageDaysSKUOutOfStockPerAsset", title: "Average Days SKU Out Of Stock Per Asset", value: 'averageSKU', ml: 60 },
    { key: "TotalAssetsMonitored", title: "Total Assets Monitored", value: 'uniqueAssetsCount', ml: 50 }
]

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const config = {
    label: "Clients",
    titleMessage: 'Select & Unselect All',
    multiSelectLabel: 'Multiple Clients',
}

const HorizontalBars = React.memo(function HorizontalBars() {
    const userData = useSelector(state => state.appReducer.userData) || {};
    const { ClientId, CurrencySymbol } = userData?.tags || {};
    const [selectedClients, setSelectedClients] = React.useState([Number(ClientId)]);
    const [activeClients, setActiveClients] = React.useState([]);
    const [dateDiff, setDateDiff] = React.useState(30);
    const [dateValue, setDateValue] = React.useState([dayjs().subtract(30, 'day'), dayjs()]);
    const [gridFilters, setGridFilters] = React.useState([]);
    const [cardValues, setCardValues] = React.useState({ actualRevenue: 0, missedRevenue: 0, upliftPotential: 0, averageUnitsSold: 0, averageSKU: 0, uniqueAssetsCount: 0 });
    const clientsList = useSelector(state => state.appReducer.clientsList) || {};
    const dispatch = useDispatch();
    const { t: trans, i18n } = useTranslation();
    const tOpts = { t: trans, i18n };
    const t = utils.t;

    React.useEffect(() => {
        let gridFilter = [];
        if (dateValue) {
            gridFilter = [{ value: dayjs(dateValue[0]).format('YYYY-MM-DD'), field: "PurityDateOnly", pivotFilterField: "StartDate", operator: ">=" }, { value: dayjs(dateValue[1]).format('YYYY-MM-DD'), field: "PurityDateOnly", pivotFilterField: "EndDate", operator: "<=" }];
        }
        if (gridFilter) {
            setGridFilters(gridFilter);
        }
    }, []);


    React.useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle icon="" titleHeading='Missed Opportunity' titleDescription='Missed Opportunity is the value of products being unavailable in stores, achieve this with a calculation on the sales rate of each product on each store and the projection of sales based on time the product were unavailable.' title='Missed Opportunity' /> });
    }, [])

    const handleClientSelectChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === -1) {
            setSelectedClients(selectedClients.length === clientsList.length ? [] : clientsList?.map(client => client.Key));
            return;
        }
        setSelectedClients(value);
    };

    function onResetClick() {
        setSelectedClients([Number(ClientId)]);
        setDateDiff(30);
        setDateValue([dayjs().subtract(30, 'day'), dayjs()]);
        setActiveClients();
        let gridFilter = [];
        if (dateValue) {
            gridFilter = [{ value: dayjs().subtract(30, 'day').format('YYYY-MM-DD'), field: "PurityDateOnly", operator: ">=", pivotFilterField: 'StartDate' }, { value: dayjs().format('YYYY-MM-DD'), field: "PurityDateOnly", pivotFilterField: "EndDate", operator: "<=" }];
        }
        if (gridFilter) {
            setGridFilters(gridFilter);
        }
    }

    function onApplyFilterClick() {
        if (dayjs(dateValue[0]).isValid() && dayjs(dateValue[1]).isValid()) {
            const selectedDate = dayjs(dateValue[0]).format('YYYY-MM-DD');
            const currentDate = dayjs(dateValue[1]).format('YYYY-MM-DD');
            const differenceInDays = dayjs(currentDate).diff(dayjs(selectedDate), 'day');
            setDateDiff(differenceInDays);
            let gridFilter = [];
            if (dateValue) {
                gridFilter = [{ value: dayjs(dateValue[0]).format('YYYY-MM-DD'), field: "PurityDateOnly", operator: ">=", pivotFilterField: 'StartDate' }, { value: dayjs(dateValue[1]).format('YYYY-MM-DD'), field: "PurityDateOnly", pivotFilterField: "EndDate", operator: "<=" }];
            }
            if (gridFilter) {
                setGridFilters(gridFilter);
            }
            setActiveClients(selectedClients);
        }
        else {
            swal({ text: "Invalid Date, please pass date in correct format", icon: "error" });
        }
    }

    const gridPivotFilter = React.useMemo(() => {
        const grFilter = [];
        if (selectedClients?.length) {
            grFilter.push({ value: selectedClients, field: "selectedClients" });
        }
        if (dateValue?.length) {
            grFilter.push({ value: dayjs(dateValue[0]).format('YYYY-MM-DD'), field: "StartDate" });
            grFilter.push({ value: dayjs(dateValue[1]).format('YYYY-MM-DD'), field: "EndDate" });
        }
        return grFilter;
    });

    function postDataFunction({ data }) {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
        const totals = { actualRevenue: 0, missedRevenue: 0, totalUnitsSold: 0, totalSKU: 0, };
        const uniqueAssets = new Set();
        data.forEach(({ AssetSerialNumber, ActualRevenue, MissedRevenue, UnitsSoldInOneDay, OutofStockDays }) => {
            uniqueAssets.add(AssetSerialNumber);
            totals.actualRevenue += ActualRevenue || 0;
            totals.missedRevenue += MissedRevenue || 0;
            totals.totalUnitsSold += UnitsSoldInOneDay || 0;
            totals.totalSKU += OutofStockDays || 0;
        });
        const uniqueAssetsCount = uniqueAssets.size;
        const upliftPotential = uniqueAssetsCount ? Math.round(((totals.actualRevenue / totals.missedRevenue) * 100) * 100) / 100 : 0;
        totals.actualRevenue = Math.round(totals.actualRevenue * 100) / 100;
        totals.missedRevenue = Math.round(totals.missedRevenue * 100) / 100;
        const averageUnitsSold = uniqueAssetsCount ? Math.round((totals.totalUnitsSold / uniqueAssetsCount) * 100) / 100 : 0;
        const averageSKU = uniqueAssetsCount ? Math.round((totals.totalSKU / uniqueAssetsCount) * 100) / 100 : 0;
        setCardValues({ ...totals, uniqueAssetsCount, upliftPotential, averageUnitsSold, averageSKU, });
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
    }


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <div style={{ fontSize: 'large', fontWeight: 500, color: 'black', marginTop: 15, marginBottom: 10 }}>
                            {t("Missed Opportunity", tOpts)} {dateDiff ? `- ${t("Last", tOpts)} ${dateDiff} ${t("Days", tOpts)}` : null}
                        </div>
                    </Item>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Item>
                        <div style={{ fontSize: 'large', fontWeight: 500, color: 'black', marginBottom: 10 }}>
                            {t("Filters", tOpts)}
                        </div>
                        <div style={{ display: "flex" }}>
                            <Grid item xs={6}>
                                <div>
                                    <DateRangePicker value={dateValue} onChange={(newValue) => setDateValue(newValue)} />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ marginLeft: 15 }} className="missed-opportunity-client-select">
                                    <MultiSelectDropdown config={config} options={clientsList} selectedOptions={selectedClients} handleChange={handleClientSelectChange} addMargin={false} width={220} subStringlength={45} />
                                </div>
                            </Grid>
                        </div>
                        <div style={{ marginTop: 10, display: 'flow-root' }}>
                            <Button variant="text" style={{ float: 'left' }} onClick={() => onResetClick()}>{t("Reset", tOpts)}</Button>
                            <Button variant="text" style={{ float: 'right' }} onClick={() => onApplyFilterClick()}>{t("Apply", tOpts)} {t("Filter", tOpts)}</Button>
                        </div>
                    </Item>
                </Grid>
            </Grid>
            <div style={{ display: "flex" }}>
                {cardsToRender.map((ele) => {
                    const { key, title, value, addCurrencySymbol = false, addPercentageSymbol = false } = ele;
                    const cardValue = cardValues[value];
                    return (
                        <Paper elevation={3} key={key} sx={{ p: 2, mt: 3, mb: 1, mr: 2, height: 150, width: 510, display: "flex", justifyContent: "center" }}>
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", height: 80, marginTop: 10, fontSize: 'medium', fontWeight: 500 }}>{t(title, tOpts)}</div>
                                <div style={{ display: "flex", justifyContent: "center", fontSize: 'large' }}>{addCurrencySymbol ? `${CurrencySymbol} ` : ''}{cardValue || 0}{addPercentageSymbol ? " %" : ""}</div>
                            </div>
                        </Paper>
                    )
                })}
            </div>
            <Grid sx={{ marginTop: 2 }}>
                <missedOpportunityOutletModel.Grid postDataFunction={postDataFunction} additionalFilters={gridFilters} reRenderKey={gridFilters} selectedClients={activeClients} gridPivotFilter={gridPivotFilter} />
            </Grid>
        </Box>
    );
})

export default HorizontalBars;